import React, { useEffect } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";

const CookieConsentBanner = () => {
  const handleAccept = () => {
    // Set cookies to true for both Google Tag Manager and Google Analytics
    Cookies.set("gatsby-gdpr-google-tagmanager", "true", { path: "/" });
    Cookies.set("gatsby-gdpr-google-analytics", "true", { path: "/" });
    
    console.log("User accepted all cookies. GTM and Google Analytics are active.");
  };

  const handleDecline = () => {
    // Remove or set cookies to false when the user declines cookies
    Cookies.set("gatsby-gdpr-google-tagmanager", "false", { path: "/" });
    Cookies.set("gatsby-gdpr-google-analytics", "false", { path: "/" });

    console.log("User declined all cookies. GTM and Google Analytics are disabled.");
  };

  // Check if GTM and Google Analytics cookies exist and are set to true, then initialize them
  useEffect(() => {
    if (Cookies.get("gatsby-gdpr-google-tagmanager") === "true") {
      console.log("Google Tag Manager is active");
      // Initialize GTM script if necessary
      //window.dataLayer = window.dataLayer || []; window.dataLayer.push({ 'event': 'gtm.js' });
    }

    if (Cookies.get("gatsby-gdpr-google-analytics") === "true") {
      console.log("Google Analytics is active");
      // Initialize Google Analytics script if necessary
      //window.ga('create', 'UA-XXXXXXXXX-X', 'auto');
      //window.ga('send', 'pageview');
    }
  }, []); // Run on mount to check if the cookies already exist

  return (
    <CookieConsent
      onAccept={handleAccept}
      onDecline={handleDecline}
      enableDeclineButton
      buttonText="Accept All Cookies"
      declineButtonText="Reject All"
      style={{
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        borderRadius: '0px',
        padding: '15px',
        fontSize: '14px',
        zIndex: '1000',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
      buttonStyle={{
        backgroundColor: '#f1c40f',
        border: 'none',
        color: 'black',
        padding: '5px 10px',
        cursor: 'pointer',
        borderRadius: '0px',
        fontSize: '14px',
        marginRight: '60px',
      }}
      declineButtonStyle={{
        backgroundColor: '#f1c40f',
        border: 'none',
        color: 'black',
        padding: '5px 10px',
        cursor: 'pointer',
        borderRadius: '0px',
        fontSize: '14px',
        marginRight: '30px',
      }}
    >
      <div>
        <h4>We use cookies</h4>
        <p style={{ marginTop: "2.4rem", fontSize: "1.4rem", lineHeight: "1.8rem" }}>
          At Fulcrum GT, we're dedicated to empowering your firm journey. That's why we use cookies to enhance your experience on our site, optimize your interactions, offer social media features, and personalize our marketing efforts. We also use cookies to deliver content and ads tailored to your interest, including on third-party websites. For more information, visit our <a href="/privacy-policy" style={{ color: "#f1c40f", textDecoration: "none" }}>Privacy Policy</a>.
        </p>
      </div>
    </CookieConsent>
  );
};

export default CookieConsentBanner;
